<template>
  <b-modal
    ref="modal"

    :title="(options.title || 'Generate personal API token')"

    size="md"
    centered
  >
    <template v-slot:default>
      <form
        class="form mb-0"
        @submit.prevent="submitForm"
      >
        <div class="form-group">
          <label>Token name <sup>Required</sup></label>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text w-50px">
                <i class="mx-auto">
                  <font-awesome-icon :icon="['fas', 'text']" />
                </i>
              </span>
            </div>
            <input
              ref="nameInput"
              type="text"
              :class="['form-control', { 'is-invalid': $v.token.name.$error }]"
              :disabled="isLoadingForm"
              v-model.trim="$v.token.name.$model"
            />
          </div>
        </div>

        <div class="form-group">
          <label>Scopes</label>
          <div class="checkbox-inline">
            <label class="checkbox checkbox-lg checkbox-disabled">
              <input type="checkbox" checked="checked" value="api:read" disabled="disabled" v-model.trim="token.scopes" />
              <span></span>
              Read (default)
            </label>

            <label class="checkbox checkbox-lg ml-10">
              <input type="checkbox" checked="checked" value="api:write" v-model.trim="token.scopes" />
              <span></span>
              Write (optional)
            </label>
          </div>
        </div>
      </form>
    </template>

    <template v-slot:modal-footer>
      <button type="button" class="btn btn-secondary" :disabled="isLoadingForm" @click.prevent="close">Cancel</button>
      <button type="submit" class="btn btn-primary" :disabled="isLoadingForm" @click.prevent="submitForm">
        Generate
      </button>
    </template>
  </b-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { validations } from '@vedicium/core-vue';
import { Mixins } from '@vedicium/metronic-vue';

import UserPersonalToken from '@/libs/classes/user_personal_token';

export default {
  mixins: [Mixins.Modal],
  name: 'userPersonalTokenModal',
  data () {
    return {
      options: {},
      isLoadingForm: false,

      token: null,
    };
  },
  validations: {
    token: {
      name: {
        required,
      },
    },
  },

  mounted () {
    // Listeners
    this.$eventhub.on('modals:settings:token:open', this.open);
    this.$eventhub.on('modals:settings:token:close', this.close);
  },
  beforeDestroy () {
    // Remove listeners
    this.$eventhub.off('modals:settings:token:open', this.open);
    this.$eventhub.off('modals:settings:token:close', this.close);
  },

  methods: {
    onFileChange (files = null) {
      if (!files || files.length === 0) {
        return;
      }

      const file = files[0];
      this.$set(this, 'file', file);
    },

    async validate () {
      await validations.validateArray([this.$v]);
    },
    async submitForm () {
      this.$set(this, 'isLoadingForm', true);

      try {
        await this.validate();

        if (this.options && typeof this.options.onSubmit === 'function') {
          await this.options.onSubmit(this.token);
        }
      } catch (e) {
        console.error(e);
        this.$errors.handle(e, { ui_element: true });
        return;
      } finally {
        this.$set(this, 'isLoadingForm', false);
      }

      this.close();
    },

    open (options = {}) {
      // Reset options
      this.$set(this, 'options', options || {});

      // Reset form
      this.$set(this, 'token', ((options && options.token) || new UserPersonalToken()));
      this.$v.$reset();

      // Add default 'api:read' & 'api:write' to scopes
      if (this.token.scopes.includes('api:read') === false) this.token.scopes.push('api:read');
      if (this.token.scopes.includes('api:write') === false) this.token.scopes.push('api:write');

      this.$refs.modal.show();
    },
    close () {
      this.$refs.modal.hide();
    },

    async onShown () {
      await this.$nextTick();

      this.$refs.nameInput.focus();
    },
  },
};
</script>
