/* eslint-disable no-await-in-loop */
import core, { AbstractClass } from '@vedicium/core-vue';

export default class Abstract extends AbstractClass {
  static async getAllResults (options = {}) {
    // Create results
    const results = [];

    // Request all using datatable format
    // eslint-disable-next-line no-constant-condition
    for (let page = 1; page < Infinity; page += 1) {
      const response = await core.ws.get(this.uri, {
        ...options,
        query: {
          ...(options.query || {}),
          format: 'datatable',
          per_page: 100,
          page,
        },
      });

      // Add results to response
      results.push(...response.data.map((row) => new this().merge(row)));

      // If the 'to' is the same or even higher than the total, we have every document we need
      if (response.to >= response.total) {
        break;
      }
    }

    return results;
  }
}
