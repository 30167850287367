<template>
  <div class="card card-custom">
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">API tokens</h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1">Manage your personal API tokens.</span>
      </div>

      <div class="card-toolbar">
        <button
          v-if="$auth.hasPermission('user_personal_tokens.create')"
          class="btn btn-primary font-weight-bolder mr-3"
          @click.prevent="openTokenModal()"
        >
          <span>Generate new token</span>
        </button>
      </div>
    </div>

    <div class="card-body">
      <!-- begin::Datatable -->
      <datatable
        ref="datatable"
        :url="datatable.url"
        :fields="datatable.fields"
        :filters="datatable.filters"
        :search-fields="datatable.searchFields"
        :on-pagination-data="onPaginationData"

        :transform="transform"
      >
        <!-- begin::Name -->
        <div slot="name" slot-scope="props">
          {{ props.rowData.name }}

          <div class="token" v-if="tokens.get(props.rowData._meta.guid)">
            <button
              :ref="`user_personal_tokens.${props.rowData._meta.guid}.token`"
              class="btn label label-inline label-lg cursor-pointer"
              v-text="tokens.get(props.rowData._meta.guid).token"
              @click="copyText(tokens.get(props.rowData._meta.guid).token)"
            />

            <b-tooltip
              :target="() => $refs[`user_personal_tokens.${props.rowData._meta.guid}.token`]"
              triggers="click blur"
              variant="primary"
            >
              Copied
            </b-tooltip>
          </div>
        </div>
        <!-- end::Name -->

        <!-- begin::Status -->
        <div slot="scopes" slot-scope="props">
          <label
            v-for="scope in props.rowData.scopes"
            :key="`user_personal_token.${props.rowData._meta.guid}.scope.${scope}`"
            class="label label-inline text-uppercase mr-3"
            v-text="scope"
          />
        </div>
        <!-- end::Status -->

        <!-- begin::Actions -->
        <div slot="actions" slot-scope="props">
          <a
            v-if="$auth.hasPermission('user_personal_tokens.delete')"
            :class="['btn btn-sm btn-clean btn-icon']"
            @click.prevent="removeDocument(props.rowData)"
          >
            <span class="svg-icon">
              <font-awesome-icon :icon="['fas', 'trash']" />
            </span>
          </a>
        </div>
        <!-- end::Actions -->
      </datatable>

      <!-- begin::Datatable footer -->
      <div class="datatable datatable-default">
        <div class="datatable-pager datatable-paging-loaded">
          <datatable-pagination
            ref="pagination"
            @vuetable-pagination:change-page="onChangePage"
          />

          <datatable-pagination-info
            ref="pagination.info"
            @vuetable-paginationinfo:change-rowcount="onChangeRowCount"
          />
        </div>
      </div>
      <!-- end::Datatable footer -->
    </div>

    <token-modal v-if="$auth.hasPermission('user_personal_tokens.create')" ref="tokenModal" />
  </div>
</template>

<script>
import { DatatableMixin } from '@vedicium/metronic-vue';
import UserPersonalToken from '@/libs/classes/user_personal_token';
import moment from 'moment-timezone';

import TokenModal from './modals/token.modal.vue';

export default {
  mixins: [DatatableMixin],
  components: {
    TokenModal,
  },
  data () {
    return {
      datatable: {
        url: UserPersonalToken.uri,
        fields: [{
          name: 'name',
          title: 'Name',
          sortField: 'name',
          titleClass: 'w-200px',
          dataClass: 'w-100px',
        }, {
          name: 'scopes',
          title: 'Scopes',
          titleClass: 'w-200px',
          dataClass: 'w-100px',
        }, {
          name: '_meta.created',
          title: 'Created',
          sortField: '_meta.created',
          titleClass: 'w-100px',
          dataClass: 'w-100px',
          formatter: (value) => moment(value).format('LLL'),
        }, {
          name: 'actions',
          title: 'Actions',
          titleClass: 'w-125px',
          dataClass: 'w-125px',
        }],
      },

      tokens: new Map(),
    };
  },

  methods: {
    transform (data) {
      const response = { ...data };
      if (response.data && Array.isArray(response.data)) {
        response.data = response.data.map((row) => new UserPersonalToken().merge(row), []);
      }
      return response;
    },

    removeDocument (document = null) {
      if (!document || document instanceof UserPersonalToken === false) {
        return;
      }

      const vm = this;
      vm.$metronic.eventhub.emit('modals:layout:confirmation:open', {
        title: 'Remove API token',
        message: `Are your sure that you want to remove API token <strong>${document.name}</strong>?`,
        buttonText: 'Remove',
        async onSubmit () {
          try {
            await document.remove();
          } catch (e) {
            vm.$errors.handle(e, { ui_element: true });
            console.error(e);
            return;
          }

          vm.$refs.datatable.reload({ query: { disable_cache: true } });
        },
      });
    },

    openTokenModal () {
      const vm = this;
      vm.$eventhub.emit('modals:settings:token:open', {
        title: 'Generate personal access token',
        token: new UserPersonalToken(),

        async onSubmit (token = null) {
          try {
            const tokenDocument = await token.create();
            vm.tokens.set(tokenDocument._meta.guid, tokenDocument);

            vm.$bvToast.toast('Please copy your personal access token now. It won\'t be shown again for security reasons', {
              toaster: 'b-toaster-top-center',
              title: 'New personal access token',
              variant: 'success',
              solid: true,
            });
          } catch (e) {
            vm.$errors.handle(e, { ui_element: true });
            console.error(e);
            return;
          }

          vm.$refs.datatable.reload({ query: { disable_cache: true } });
        },
      });
    },

    copyText (text = null) {
      return text && navigator.clipboard.writeText(text);
    },
  },
};
</script>
